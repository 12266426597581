<template>
  <el-dialog
      :title="!dataForm.id ? '新增组织' : '修改'"
      :close-on-click-modal="false"
      :visible.sync="visible" @closed="closeDialog()">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
             label-width="80px"> 
      <el-form-item label="上级组织" prop="parentOrgName"> 
        <el-input :disabled="true" v-model="treeNode.name" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="所属区域" prop="regionId"> 
        <!-- <sysOrg :form="dataForm" field="regionId" :multiple="false" :lazy="true"></sysOrg> -->
        <region-tree type="4" :inType="false" :rootId="treeNode.orgRegionId" placeholder="请点击以选择区域" v-model="dataForm.regionId" @change="onRegionIdChange" > </region-tree>
      </el-form-item> 
      <el-form-item label="组织编码" prop="orgCode" >
        <el-input v-model.trim="dataForm.orgCode" placeholder="" maxlength="30"></el-input>
      </el-form-item> 
      <el-form-item label="组织名称" prop="name" >
        <el-input v-model.trim="dataForm.name" placeholder="" maxlength="30">姓名</el-input>
      </el-form-item>
      <el-form-item label="排序" prop="orderBy">
        <el-input-number style="width: 100%;" v-model="dataForm.orderBy" :min="0" placeholder="请输入排序号"></el-input-number>
      </el-form-item>
      <!-- <el-form-item label="邮政" prop="post">
        <el-input v-model="dataForm.post" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="tel">
        <el-input v-model="dataForm.tel" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="传真" prop="fax">
        <el-input v-model="dataForm.fax" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="dataForm.email" placeholder=""></el-input>
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span> 
  </el-dialog>
</template>

<script>
import {getById, saveOrUpdate} from '@/api/sys/sysorg.js'
import { getList } from '@/api/sys/region'  

export default { 
  data() {
    return {
      visible: false,
      isOnSubmit: false,
      treeNode: {
        id: '',
        type: '',
        orgId: ''
      }, 
      dataForm: {
        id: null,
        name: '',
        orgCode: '',
        parentOrgId: 0,
        orderBy: '',
        post: '',
        regionId:'',
        tel: '',
        fax: '',
        email: '',
        workCalendarId: '',
        validFlag: '',
        lastUpdateDate: '',
        lastUpdatedBy: '',
        creationDate: '',
        createdBy: '',
        lastUpdateIp: '',
        version: '',
        responsibleDeptId: '',
        orgIdentity: '',
        isMulOrg: ''
      },
      dataRule: {
        name: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ], 
        orderBy: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ], 
        regionId: [
           { required: true, message: '不能为空', trigger: 'change' }
         ], 
      }
    }
  },

  methods: {
    init(id, treeNode) {
      this.dataForm.id = id
      this.treeNode = treeNode
      this.dataForm.parentOrgId = treeNode.id 
      this.visible = true
      this.isOnSubmit = false
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          getById(id).then(({data}) => {
            if (data && data.code === 0) {
              this.dataForm.name = data.data.name
              this.dataForm.orgCode = data.data.orgCode
              this.dataForm.parentOrgId = data.data.parentOrgId
              this.dataForm.orderBy = data.data.orderBy
              this.dataForm.post = data.data.post
              this.dataForm.tel = data.data.tel
              this.dataForm.fax = data.data.fax
              this.dataForm.regionId = data.data.regionId
              this.dataForm.email = data.data.email
              this.dataForm.workCalendarId = data.data.workCalendarId
              this.dataForm.validFlag = data.data.validFlag
              this.dataForm.lastUpdateDate = data.data.lastUpdateDate
              this.dataForm.lastUpdatedBy = data.data.lastUpdatedBy
              this.dataForm.creationDate = data.data.creationDate
              this.dataForm.createdBy = data.data.createdBy
              this.dataForm.lastUpdateIp = data.data.lastUpdateIp
              this.dataForm.version = data.data.version
              this.dataForm.responsibleDeptId = data.data.responsibleDeptId
              this.dataForm.orgIdentity = data.data.orgIdentity
              this.dataForm.isMulOrg = data.data.isMulOrg
            }
          }).catch((err) => {
            this.$message.error(err)
          })
        }
      })
    },
    closeDialog(){
      // this.dataForm.regionId =''
      this.$emit("destoryOrg")
    },
    // 将区域ID默认为组织编码
    // onRegionIdChange(region) {
    //   if(!this.dataForm.orgCode) {
    //     this.dataForm.orgCode = region.code;
    //   }
    // },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.isOnSubmit = true
          const data_ = {
            'id': this.dataForm.id || undefined,
            'name': this.dataForm.name,
            'orgCode': this.dataForm.orgCode,
            'parentOrgId': this.dataForm.parentOrgId,
            'orderBy': this.dataForm.orderBy,
            'post': this.dataForm.post,
            'tel': this.dataForm.tel,
            'fax': this.dataForm.fax,
            'regionId': this.dataForm.regionId,
            'email': this.dataForm.email,
            'workCalendarId': this.dataForm.workCalendarId,
            'validFlag': this.dataForm.validFlag,
            'lastUpdateDate': this.dataForm.lastUpdateDate,
            'lastUpdatedBy': this.dataForm.lastUpdatedBy,
            'creationDate': this.dataForm.creationDate,
            'createdBy': this.dataForm.createdBy,
            'lastUpdateIp': this.dataForm.lastUpdateIp,
            'version': this.dataForm.version,
            'responsibleDeptId': this.dataForm.responsibleDeptId,
            'orgIdentity': this.dataForm.orgIdentity,
            'isMulOrg': this.dataForm.isMulOrg
          }
          saveOrUpdate(data_).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500
              })
              this.visible = false
              this.$emit('refreshDataList');
              this.$emit('treeget');
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(() => {
            this.isOnSubmit = false
          })
        }
      })
    }
  }
}
</script>
